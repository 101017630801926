import React from "react";
import { Flex } from "antd";

const Footer: React.FC = () => {
  return (
    <Flex align="center" justify="center" vertical style={{ height: 120 }}>
      <footer>
        <Flex justify="center" align="center" vertical>
          <div><b>Reto Froelicher ©{new Date().getFullYear()}</b></div>
          <div>Created with ant.design, React and TypeScript</div>
        </Flex>
      </footer> 
    </Flex>
  );
};

export default Footer;
